<template>
  <div>
    <h2>Itt kezdődött</h2>

    <p>
      Keressétek fel bimbózó kapcsolatotok egy vagy több ikonikus helyszínét!  Álljatok meg picit!  Bizony... 
      szabad elérzékenyülni is... Készítsetek egy SZELFIT magatokról, küldjétek el a marosvet75 [kukac] gmail.com címre! Ha találtok egy képet kettőtökről azokból 
      az évekből, kérjük azt is küldjétek el!
    </p>

    <p>
      Idei mottónk: "<i>Bennünk a létra</i>". Bizony már nem az alsó fokokat koptatjuk, de nem szabad megállnuk. Térjetek be egy templomba a kirándulásotok
      során, adjatok hálát, kérjetek, kapjatok!!! A feladatlapon kaphattok segítséget, ha nehéz. Pár sorban írjátok le imátokat!. Hozzátok majd magatokkal 
      a Közös Imádságra Február 5-én! Természetesen nem megosztást kérünk majd erről, kettőtők és az Úr szavai köztetek maradnak.
    </p>

    <a href="/flyer.jpg" download><img class="download-icon" src="../assets/pdf-icon_black.png"></a>
    <p>
      Ha még nem kaptátok meg az ehhez kapcsolódó feladatlapot, vagy netán elvesztettétek, a pdf ikonra kattintva innen is le tudjátok tölteni.
    </p>
  </div>
</template>
<script>
  //import EmbeddedMapComponent from './subcomponents/EmbeddedMapComponent.vue'

  export default {
    name: 'trials',
    components: {
      //'embedded-map': EmbeddedMapComponent
    },
    data: function() {
      return {
        publicPath: process.env.BASE_URL
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .download-icon {
    width: 44px;
    float: left;
    margin-top: 26px;
    margin-right: 8px;
    padding-right: 4px;
  }

  .sign {
    float: left;
    width: 168px;
    margin-top: 24px;
    margin-right: 8px;
    padding-right: 4px;
  }

  @media (max-width: 920px) {
    .sign {
      width: 150px;
    }
  }
</style>
